import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, Link, graphql } from "gatsby";
import { connect } from "react-redux";
import "../../styles/layout.css";
import Img from "gatsby-image";

const navbar = props => (
  <StaticQuery
    query={graphql`
      query {
        chopstickLogo: file(relativePath: { eq: "logo2.png" }) {
          childImageSharp {
            fluid(maxWidth: 202, maxHeight: 140) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        yelp: file(relativePath: { eq: "yelp.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="Header" style={{ marginBottom: 0 }}>
        <Img
          alt="Chopstick Express's Primary Logo with chopsticks and a plate"
          fluid={data.chopstickLogo.childImageSharp.fluid}
          className="logo"
          style={{
            alignSelf: "center",
            marginBottom: "1em",
            marginTop: "1em",
          }}
        />
        <div className="links">
          <ul>
            <Link to="/">
              <li>Home</li>
            </Link>
          </ul>
          <ul>
            <Link to="/menu">
              <li>Menu</li>
            </Link>
          </ul>
          <ul>
            <Link to="/cart">
              <li>Cart ({props.orderLength})</li>
            </Link>
          </ul>
        </div>
        <Img
          alt="Yelp emblem showing that people love Chopstick Express"
          fluid={data.yelp.childImageSharp.fluid}
          className="yelp"
        />
      </div>
    )}
  />
);

const mapStateToProps = state => ({
  orderLength: state.order.items.length,
});

navbar.propTypes = {
  orderLength: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(navbar);
