import React from "react";
import { SocialIcon } from "react-social-icons";
import { Link } from "gatsby";
import "../../styles/layout.css";

export default function footer() {
  return (
    <div style={{ backgroundColor: "rgb(252,252,252)" }}>
      <hr />
      <div className="Footer">
        <div className="topFooterHalf">
          <div>
            <h3>Chopstick Express Social</h3>
            <div className="socialIcons">
              <SocialIcon url="https://www.yelp.com/biz/chopstick-express-saint-petersburg" />
              <SocialIcon url="https://www.google.com/maps/place/Chopstick+Express/@27.808233,-82.6411112,17z/data=!4m7!3m6!1s0x88c2e15ae7424267:0x6e14f764d4d734d1!8m2!3d27.808233!4d-82.6389225!9m1!1b1?authuser=1" />
              <SocialIcon url="https://www.facebook.com/chopstickexpressinc/" />
            </div>
          </div>
          <br className="mobileSpace" />
          <div className="otherLinks">
            <h3>Legal Links</h3>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/tos">Terms of Service</Link>
          </div>
        </div>
        <br />
        <br />
        <div className="chopstickPlug">
          <p>&#9400;{new Date().getFullYear()} Chopstick Express</p>
          <div />
          <div />
          <a href="https://getfoodweb.com" style={{ color: "white" }}>
            Site by FoodWeb
          </a>
        </div>
      </div>
    </div>
  );
}
