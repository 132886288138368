import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import Navbar from "./navbar";
import Footer from "./footer";
import "normalize.css";
import { persistor } from "../../state/createStore";

export default function layout({ children, location }) {
  return (
    <div>
      {location === "/" ? (
        <div>
          <Navbar />
          {children}
          <Footer />
        </div>
      ) : (
        <PersistGate loading={null} persistor={persistor}>
          <Navbar pathname={location} />
          {children}
          <Footer />
        </PersistGate>
      )}
    </div>
  );
}
